import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { PortfolioList } from "components/portfolioList";

const RootIndex = ({ location, data }) => {
  const portfolioItems = data.contentfulPortfolioList.portfolios;
  return (
    <Layout location={location}>
      <Helmet title="Hakio Consulting Oy" />
      <div className="my-8">
        <PortfolioList items={portfolioItems} />
      </div>
    </Layout>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    contentfulPortfolioList {
      portfolios {
        title
        mainTechnology
        technologies
        url
        image {
          gatsbyImageData(
            width: 600
            cropFocus: TOP
            height: 400
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 85
          )
        }
      }
    }
  }
`;
