import React from "react";
import PortfolioItem from "components/portfolioItem";

export const PortfolioList = ({ items }) => {
  return (
    <>
      <div className="space-y-12">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl font-bold sm:text-4xl">Portfolio</h2>
        </div>
        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          {items.map((item) => (
            <PortfolioItem key={item.title} item={item} />
          ))}
        </ul>
      </div>
    </>
  );
};
