import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

export const PortfolioItem = ({ item }) => {
  const image = getImage(item.image);

  return (
    <li>
      <div className="space-y-4">
        <a href={item.url} target="_blank" rel="noreferrer">
          <GatsbyImage
            className="shadow-lg rounded-lg"
            alt={item.title}
            image={image}
          />
        </a>

        <div className="space-y-2">
          <div className="text-lg leading-6 font-medium space-y-1">
            <a href={item.url}>
              <h3>{item.title}</h3>
            </a>

            <p className="text-blue-600">{item.mainTechnology}</p>
          </div>
          <div className="flex flex-wrap">
            <p className="text-gray-400 text-sm break-normal hover:text-blue-500">
              {item.technologies.join(", ")}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};
export default PortfolioItem;
